@tailwind base;
@tailwind components;
@tailwind utilities;

/* @font-face {
  src: local("MyFont"), url(./fonts/proximanova-regular.otf) format("opentype");
  font-family: "Proximanova";
  font-weight: 400;
}
@font-face {
  src: local("MyFont"), url(./fonts/proximanova-medium.otf) format("opentype");
  font-family: "Proximanova";
  font-weight: 500;
}
@font-face {
  src: local("MyFont"), url(./fonts/proximanova-semibold.otf) format("opentype");
  font-family: "Proximanova";
  font-weight: 600;
}
@font-face {
  src: local("MyFont"), url(./fonts/proximanova-bold.otf) format("opentype");
  font-family: "Proximanova";
  font-weight: 700;
} */
